import request from "@/utils/request"

//查询 文章/快讯 列表一览
export function articleList(data) {
  return request({
    url: "/article/list",
    method: "post",
    data
  })
}

//新增快讯/编辑快讯
export function addArticle(data) {
  return request({
    url: "/article/add",
    method: "post",
    data
  })
}

//获取快讯详情
export function selectById(query) {
  return request({
    url: "/article/getInfo",
    method: "get",
    params: query
  })
}

// 根据快讯名称模糊查询id和名称
export function selectIdAndArticleName(query) {
  return request({
    url: "/article/selectIdAndName",
    method: "get",
    params: query
  })
}

// 根据活动名称模糊查询id和名称
export function selectIdAndActivityName(query) {
  return request({
    url: "/activity/selectIdAndName",
    method: "get",
    params: query
  })
}
// 根据项目名称模糊查询id和名称
export function selectIdAndProjectName(query) {
  return request({
    url: "/demand/selectIdAndName",
    method: "get",
    params: query
  })
}

//定时获取新的公众号文章
export function getPublicNumberArticle(query) {
  return request({
    url: "/article/getPublicNumberArticle",
    method: "get",
    params: query
  })
}

//冻结、解冻/上下架
export function updateType(data) {
  return request({
    url: "/article/updateType",
    method: "post",
    data
  })
}
