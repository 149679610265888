<template>
  <div class="add_article">
    <div class="content_body">
      <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <div class="form_item_card">
          <div class="card_title">基本信息</div>
          <el-form-item label="文章名称" prop="articleName">
            <el-input v-model="form.articleName" placeholder="请输入文章名称" />
          </el-form-item>
          <el-form-item label="文章来源" prop="articleType">
            <el-radio-group v-model="form.articleType">
              <el-radio label="1">CUC商盟</el-radio>
              <el-radio label="2">长三角商会秘书长联盟</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="封面图" prop="surfaceDrawing">
            <div class="com_upload">
              <img-big-upload
                :imageShow.sync="form.surfaceDrawing"
                :addTitleShow="false"
                @removeImg="removeImgCover"
                @uploadOneImgShow="uploadOneImgShowCover"
              />
            </div>
            <div class="ft-12 color5 mar-top-10">
              （建议整体尺寸为577*389px，图片格式应为jpg、jpeg、png，图片应小于12MB）
            </div>
          </el-form-item>
          <el-form-item label="文章描述" prop="articleDescription">
            <Editorbar v-model="form.articleDescription" :isClear="true"></Editorbar>
          </el-form-item>
        </div>
        <div class="form_item_card center_card">
          <div class="card_title">关联信息</div>
          <el-form-item label="关联快讯" prop="">
            <div class="row" v-for="(item, index) in form.articleRelevanceVos" :key="index">
              <el-autocomplete
                class="flex_input"
                v-model="item.articleName"
                :fetch-suggestions="relateArticleNameQuerySearch"
                placeholder="请输入关键词检索文章"
                :trigger-on-focus="false"
                @select="relateArticleNameSelect($event, item)"
                @input="relateArticleNameChanged"
              >
              </el-autocomplete>
              <div class="icon_btn" @click="addRelateArticleBtn">
                <i class="el-icon-circle-plus add"></i>
              </div>
              <div class="icon_btn" v-if="index > 0" @click="delRelateArticleBtn(index)">
                <i class="el-icon-delete del"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关联项目" prop="">
            <div class="row" v-for="(item, index) in form.projectRelevanceVos" :key="index">
              <el-autocomplete
                class="flex_input"
                v-model="item.articleName"
                :fetch-suggestions="relateProjectNameQuerySearch"
                placeholder="请输入关键词检索项目"
                :trigger-on-focus="false"
                @select="relateProjectNameSelect($event, item)"
                @input="relateProjectNameChanged"
              >
              </el-autocomplete>
              <div class="icon_btn" @click="addRelateProjectBtn">
                <i class="el-icon-circle-plus add"></i>
              </div>
              <div class="icon_btn" v-if="index > 0" @click="delRelateProjectBtn(index)">
                <i class="el-icon-delete del"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关联活动" prop="">
            <div class="row" v-for="(item, index) in form.activityRelevanceVos" :key="index">
              <el-autocomplete
                class="flex_input"
                v-model="item.articleName"
                :fetch-suggestions="relateActivityNameQuerySearch"
                placeholder="请输入关键词检索活动"
                :trigger-on-focus="false"
                @select="relateActivityNameSelect($event, item)"
                @input="relateActivityNameChanged"
              >
              </el-autocomplete>
              <div class="icon_btn" @click="addRelateActivityBtn">
                <i class="el-icon-circle-plus add"></i>
              </div>
              <div class="icon_btn" v-if="index > 0" @click="delRelateActivityBtn(index)">
                <i class="el-icon-delete del"></i>
              </div>
            </div>
          </el-form-item>

          <!-- <el-form-item label="关联秘书长" prop="commodityName">
            <div class="general_input">
              <div class="input_flex" v-for="(item, index) in list" :key="index">
                <div style="padding: 5px 0px">
                  <el-input v-model="item.general" placeholder="请输入秘书长名称" />
                </div>
                <span style="color: #4e93fb" @click="addgeneral"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delgeneral(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关联企业" prop="commodityName">
            <div class="general_input">
              <div class="input_flex" v-for="(item, index) in list" :key="index">
                <div style="padding: 5px 0px">
                  <el-input v-model="item.general" placeholder="请输入关联企业名称" />
                </div>
                <span style="color: #4e93fb" @click="addgeneral"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delgeneral(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item> -->
        </div>
        <div class="form_item_card">
          <div class="card_title">小程序分享链接信息</div>
          <el-form-item label="分享链接标题" prop="">
            <el-input v-model="form.shareLinkBanner" placeholder="请输入分享链接标题" />
          </el-form-item>
          <el-form-item label="分享链接图片" prop="">
            <div class="com_upload">
              <img-big-upload
                :imageShow.sync="form.shareLinkPicture"
                :addTitleShow="false"
                @removeImg="removeImgAppLink"
                @uploadOneImgShow="uploadOneImgShowAppLink"
              />
            </div>
            <div class="ft-12 color4 mar-top-10">
              (建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="detail_bottom">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button plain @click="back">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  selectById,
  addArticle,
  selectIdAndArticleName,
  selectIdAndActivityName,
  selectIdAndProjectName
} from '@/api/articlecenter'
const defaultForm = Object.freeze({
  // 关联快讯列表
  articleRelevanceVos: [
    {
      articleName: '',
      id: null
    }
  ],
  // 关联项目列表
  projectRelevanceVos: [
    {
      articleName: '',
      id: null
    }
  ],
  // 关联活动列表
  activityRelevanceVos: [
    {
      articleName: '',
      id: null
    }
  ],
  articleDescription: '', // 快讯描述
  articleName: '', // 快讯名称
  articleType: '1', // 资源来源（1：CUC商盟；2：长三角商会秘书长联盟）
  createId: null,
  createTime: '',
  id: null,
  relevanceArticleId: '', // 关联快讯id
  relevanceArticleName: '', // 关联快讯名称
  shareLinkBanner: '', // 分享链接标语
  shareLinkPicture: '', // 分享链接图片
  surfaceDrawing: '', // 封面图
  updateId: null,
  updateTime: ''
})
export default {
  name: 'addarticle',
  data() {
    return {
      messageCount: 0, // 节流阀
      dialogVisible: false,
      form: { ...defaultForm }, //表单收集
      // 表单校验规则
      rules: {
        articleName: [
          { required: true, message: '请输入文章名称', trigger: 'blur' }
          // { min: 2, max: 15, message: '长度在2到15个字符之间', trigger: 'blur' }
        ],
        articleType: [{ required: true, message: '请选择文章来源', trigger: 'blur' }],
        surfaceDrawing: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        articleDescription: [{ required: true, min: 1, message: '请输入文章描述', trigger: 'blur' }]
      },
      relateArticleList: [], //查询到的关联快讯列表
      relateProjectList: [], //查询到的关联快讯列表
      relateActivityList: [] //查询到的关联快讯列表
    }
  },
  computed: {
    id() {
      const id = this.$route.query.id
      return id
    }
  },
  created() {
    if (this.id) {
      this.form.id = this.id
      this.search()
    }
  },
  methods: {
    // 查询文章详情
    async search() {
      const { data: res } = await selectById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
        if (!this.form.surfaceDrawing) {
          this.form.surfaceDrawing = ''
        }
        if (!this.form.shareLinkPicture) {
          this.form.shareLinkPicture = ''
        }
        if (!this.form.articleRelevanceVos || this.form.articleRelevanceVos.length < 1) {
          this.form.articleRelevanceVos = [
            {
              articleName: '',
              id: null
            }
          ]
        }
        if (!this.form.activityRelevanceVos || this.form.activityRelevanceVos.length < 1) {
          this.form.activityRelevanceVos = [
            {
              articleName: '',
              id: null
            }
          ]
        }
        if (!this.form.projectRelevanceVos || this.form.projectRelevanceVos.length < 1) {
          this.form.projectRelevanceVos = [
            {
              articleName: '',
              id: null
            }
          ]
        }
      } else {
        this.$message.error('获取文章详情失败!')
      }
    },
    //添加关联快讯 item
    addRelateArticleBtn() {
      this.form.articleRelevanceVos.push({
        articleName: '',
        id: null
      })
    },
    //删除关联快讯 item
    delRelateArticleBtn(index) {
      this.form.articleRelevanceVos.splice(index, 1)
    },
    //快讯名称输入框变化
    relateArticleNameChanged() {
      this.relateArticleList = []
    },
    //选择快讯名称
    async relateArticleNameSelect(val, item) {
      const query = {
        type: '1', // 1是快讯，2是活动
        id: this.id ? this.id : 0,
        articleName: val.value
      }
      const { data: res } = await selectIdAndArticleName(query)
      if (res.resultCode === 200) {
        const obj = res.data[0]
        item.id = obj.id
        this.relateArticleList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询快讯名称
    async relateArticleNameQuerySearch(str, cb) {
      if (str != '') {
        const query = {
          type: '1', // 1是快讯，2是活动
          id: this.id ? this.id : 0,
          articleName: str
        }
        const { data: res } = await selectIdAndArticleName(query)
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            if (!this.id || this.id != item.id) {
              // 不展示当前编辑的快讯
              this.relateArticleList.push({
                value: item.articleName,
                id: item.id
              })
            }
          })
          cb(this.relateArticleList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    // 关联项目
    //添加关联项目 item
    addRelateProjectBtn() {
      this.form.projectRelevanceVos.push({
        articleName: '',
        id: null
      })
    },
    //删除关联项目 item
    delRelateProjectBtn(index) {
      this.form.projectRelevanceVos.splice(index, 1)
    },
    //项目名称输入框变化
    relateProjectNameChanged() {
      this.relateProjectList = []
    },
    //选择项目名称
    async relateProjectNameSelect(val, item) {
      const query = {
        id: this.id ? this.id : 0,
        projectName: val.value
      }
      const { data: res } = await selectIdAndProjectName(query)
      if (res.resultCode === 200) {
        const obj = res.data[0]
        item.id = obj.id
        this.relateProjectList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询项目名称
    async relateProjectNameQuerySearch(str, cb) {
      if (str != '') {
        const query = {
          id: this.id ? this.id : 0,
          projectName: str
        }
        const { data: res } = await selectIdAndProjectName(query)
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.relateProjectList.push({
              value: item.articleName,
              id: item.id
            })
          })
          cb(this.relateProjectList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    // 关联活动
    //添加关联活动 item
    addRelateActivityBtn() {
      this.form.activityRelevanceVos.push({
        articleName: '',
        id: null
      })
    },
    //删除关联活动 item
    delRelateActivityBtn(index) {
      this.form.activityRelevanceVos.splice(index, 1)
    },
    //活动名称输入框变化
    relateActivityNameChanged() {
      this.relateActivityList = []
    },
    //选择活动名称
    async relateActivityNameSelect(val, item) {
      const query = {
        id: this.id ? this.id : 0,
        activityName: val.value
      }
      const { data: res } = await selectIdAndActivityName(query)
      if (res.resultCode === 200) {
        const obj = res.data[0]
        item.id = obj.id
        this.relateActivityList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询活动名称
    async relateActivityNameQuerySearch(str, cb) {
      const query = {
        id: this.id ? this.id : 0,
        activityName: str
      }
      if (str != '') {
        const { data: res } = await selectIdAndActivityName(query)
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.relateActivityList.push({
              value: item.articleName,
              id: item.id
            })
          })
          cb(this.relateActivityList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    //获取图片长地址  小程序分享链接图片
    uploadOneImgShowAppLink(val) {
      this.form.shareLinkPicture = val
    },
    // 删除图 小程序分享链接图片
    removeImgAppLink(val) {
      this.form.shareLinkPicture = ''
    },
    // 删除图片 封面图
    removeImgCover(val) {
      this.form.surfaceDrawing = ''
    },
    //获取图片长地址  封面图
    uploadOneImgShowCover(val) {
      this.form.surfaceDrawing = val
    },
    // 重置消息提示节流阀
    resetMessageCount() {
      this.messageCount++
      setTimeout(() => {
        this.messageCount = 0
      }, 1500)
    },
    // 保存按钮
    async save() {
      if (this.messageCount === 0) {
        this.resetMessageCount()
        if (!this.form.articleDescription) {
          this.$message.error('请输入文章描述')
        } else {
          const { data: res } = await addArticle(this.form)
          if (res.resultCode === 200) {
            this.$message.success('保存成功!')
            this.$router.go(-1)
          } else {
            this.$message.error('保存失败!')
          }
        }
      }
    },
    // 返回按钮
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.add_article {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;

  .content_body {
    width: 100%;
    height: calc(100% - 72px);
    overflow-y: auto;
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
  }

  .detail_bottom {
    width: 100%;
    margin-top: auto;
    padding: 16px 0;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    border-top: #dcdfe6 1px solid;
    ::v-deep .el-button:hover {
      opacity: 0.8;
    }
    ::v-deep .el-button--primary {
      min-width: 110px;
      color: #fff;
      background-color: #4e93fb;
    }
    ::v-deep .is-plain {
      min-width: 110px;
      color: #4e93fb;
      background-color: #fff;
      border: #4e93fb 1px solid;
    }
  }
  .form_item_card {
    width: 100%;
    background-color: #fff;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 8px;
    .card_title {
      font-size: 20px;
      line-height: 24px;
      color: #1a1a1a;
      margin-bottom: 16px;
    }
    .row {
      align-items: center;
      margin-bottom: 10px;
    }
    .icon_btn {
      cursor: pointer;
      margin: 0 10px;
      i {
        font-size: 18px;
      }
    }
    .add {
      color: #4e93fb;
    }
    .del {
      color: #ff7575;
    }
  }
  .center_card {
    margin: 16px 0;
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
  }
}

::v-deep .editor {
  width: 600px;
}

::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 180px;
}
::v-deep .form_item_card .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .form_item_card .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 600px;
}

::v-deep .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 600px;
}
.ft-12 {
  font-size: 12px;
  line-height: 16px;
}
.color4 {
  color: #6ba1ff;
}
.color5 {
  color: #cdcdcd;
}
.mar-top-10 {
  margin-top: 10px;
}
</style>
